<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <!-- <div class="my-3"> -->
        
          <div class="card-body px-0 pb-2">
            
            <div class="table-responsive"> 
                <div class="float-end px-2">
                  <button @click="search()" class="btn bg-gradient-secondary btn-sm" ref="showRemove" v-tooltip="'Refresh'"><i class="fa-solid fa-arrow-rotate-right text-white"></i></button>
                </div>
                <table class="table table-fluid align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">File Name</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Date Coverage</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Message</th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="!cost_data_exports.length">
                      <td v-if="isLoading" colspan="100%" class="text-sm">
                        <i class="fas fa-cog fa-spin me-2"></i>Loading...
                      </td>
                      <td v-else colspan="100%">No data</td>
                    </tr>
                    <tr v-else class="text-sm" v-for="cost_data_export in cost_data_exports" :key="cost_data_export.id">
                      <td> {{ cost_data_export.name }}</td>       
                      <td>
                        <span class="badge badge-info" v-if="cost_data_export.status == 0">New</span>
                        <span class="badge badge-warning" v-if="cost_data_export.status == 1"><i class="fas fa-cog fa-spin me-2"></i>In-progress</span>
                        <span class="badge badge-success" v-if="cost_data_export.status == 2">Completed</span>
                        <span class="badge badge-danger" v-if="cost_data_export.status == 3">Error</span>
                      </td>
                      <td>
                        {{ cost_data_export.parameters.submit_date }}
                      </td>
                      <td class="text-wrap">
                        {{ cost_data_export.message }}
                      </td>
                      <td>
                        <a class="link me-3" :href="cost_data_export.absolute_path" v-if="cost_data_export.status == 2">
                          <i class="fa-solid fa-download text-secondary"></i>
                        </a>
                        <a class="link me-3" @click="regenerate(cost_data_export)" v-tooltip="'Reset import process'">
                          <i class="fa-solid fa-arrows-rotate text-secondary"></i>
                        </a>
                        <a class="link me-3" @click="showRemove(cost_data_export)" ref="showRemove" v-tooltip="'Delete'">
                          <i class="fas fa-trash text-secondary"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
 
            </div>
          </div>
          <div class="card-footer pt-0">
            <pagination :meta="meta" v-on:update="getExports()"/>
          </div>
        <!-- </div> -->
      </div>
     
    </div>

    <el-dialog
      id="modal-remove"
      v-model="dialogVisible"
      title="Are you sure?"
      width="500"
    >
      <div v-if="to_remove">
        You are about to remove export no. {{ to_remove.id }}, Proceed?
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button type="danger" @click="remove">
            Confirm
          </el-button>
        </div>
      </template>
    </el-dialog>
 
  </div>
</template>

<script>

import { CostDataExport } from '@/api_services/cost_data_export'
import { CardImport } from '@/api_services/card_import'
import $ from 'jquery'

export default {
  watch: {
    '$route.params.category': {
      handler(value) {
        this.meta.page = 1
        this.getExports()
      },
      deep: true
    }
  },
  props: {
    category: {
      type: String,
      required: false
    },
  },
  data() {
    return {
      dialogVisible: false,
      isLoading: true,
      cost_data_exports:[],
      to_remove: null,
      meta: {
        page: 1, total: 0, pages: 1
      },
      clear_upload: 0
    }
  },
  created: function() {
    this.getExports()
  },
  methods: {
    search() {
      this.meta.page = 1
      this.getExports()
    },
    remove(bvModalEvt) {
      bvModalEvt.preventDefault()
      CostDataExport.delete({id: this.to_remove.id})
        .then(result => {
          this.$toast.success('Removed successfully')
          this.$nextTick(() => {
            this.dialogVisible = false
          })
          this.getExports()
        })
        .catch(error => {
          this.$toast.error('An error occured while removing export')
          console.error('error:', error.response.data)
        })
    },
    showRemove(type) {
        this.to_remove = Object.assign({}, type);
        this.dialogVisible = true
    },
    regenerate(card) {
      let obj = Object.assign({}, card);
      obj.status = 1
      CardImport.reset(obj).then(result => {
          this.$toast.success('Saved successfully')
          this.reset()
          this.getImports()
        })
        .catch(error => {
          this.$toast.error('An error occured while saving import')
          console.error('error:', error.response.data)
        })
    },
    getExports() {
      this.isLoading = true
      CostDataExport.get({ params: { page: this.meta.page, category: this.category }})
        .then(result => {
            this.meta = result.data.meta
            this.cost_data_exports = result.data.collection
            console.log(result.data)
        })
        .catch( error => {
          this.$toast.error('An error occured while fetching exports')
          console.error('error:', error.response.data)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  }
}
</script>