<template>
  <sidebar-content-component></sidebar-content-component>
</template>

<script>
import SidebarContentComponent from './SidebarContentComponentNew.vue'

export default {
  components: { SidebarContentComponent },
  name: 'SidebarComponent',
  data() {
    return {
      hide_sidebar: false
    }
  },
  methods: {
    toggle() {
      document.body.classList.toggle('sb-sidenav-toggled')
      document.getElementsByClassName('menu-label').toggle('sb-sidenav-toggled')
      this.hide_sidebar = true
      localStorage.setItem('hide-sidebar', this.hide_sidebar)
    }
  }
}
</script>

<style lang="css" scoped>
/* #sidebar-wrapper {
    margin-left: 0;
    transition: margin 0.25s ease-out;
    text-align: left;
    width: 300px;
  } */

@media (min-width: 768px) {
  /* #sidebar-wrapper {
      margin-left: 0;
    }

    #page-content-wrapper {
      min-width: 0;
      width: 100%;
    } */

  /* body.sb-sidenav-toggled #app #sidebar-wrapper {
     margin-left: -222px;
    } */

  /* #sidebar.sb-sidenav-toggled li a span.menu-label{
      color: aquamarine;
    } */
}

/* @media (max-width: 767px) {
    #sidebar-wrapper {
      display: none;
    }
  } */
</style>
