<template>
  <div class="" style="text-align:left">
    <div class="card">
      <div class="card-header">
        <h5>Overall Weighting</h5>
        <span class="text-wrap"
          >
          <p>Here you can customise the overall weightings assigned to different factors of the suppliers offering.</p>
          <p>By modifying these weightings, you can tailor the level of importance for each element of their product or service based on your preferences and specific requirements. This empowers you to prioritise and align the evaluation process to meet your organisation’s unique procurement needs.</p>
          <p>Once you have defined your overall weightings, please select the 'Submit Overall Weighting' button to generate the new ratings for your qualifying suppliers.</p>
          <p style="font-weight: 600; color: red;">Please note: the overall weightings must total 100%.</p>
        </span>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-borderless">
            <tbody class="text-sm">
              <tr>
                <td>Product/Service Match</td>
                <td>
                  <input
                    class="form-control form-control-sm"
                    type="number"
                    min="0"
                    max="100"
                    v-model="form.product_match"
                  />
                </td>
              </tr>
              <tr>
                <td>Price</td>
                <td>
                  <input
                    class="form-control form-control-sm"
                    type="number"
                    min="0"
                    max="100"
                    v-model="form.price"
                  />
                </td>
              </tr>
              <tr>
                <td>Social Value</td>
                <td>
                  <input
                    class="form-control form-control-sm"
                    type="number"
                    min="0"
                    max="100"
                    v-model="form.social_value"
                  />
                </td>
              </tr>
              <tr>
                  <td>Management Information</td>
                  <td>
                    <input
                      class="form-control form-control-sm"
                      type="number"
                      min="0"
                      max="100"
                      v-model="form.management_info"
                    />
                  </td>
                </tr>
              <!-- <tr>
                <td>Environmental Impact</td>
                <td>
                  <input
                    class="form-control form-control-sm"
                    type="number"
                    min="0"
                    max="100"
                    v-model="form.environment_impact"
                  />
                </td>
              </tr> -->
            </tbody>
          </table>
          <div v-if="params.step > 1 || params.page > 2">
            <h6>Granular Weightings</h6>
            <p>With our user-friendly tool, you can further tailor your search results to align perfectly with your organisation's preferences and priorities by adjusting the score weighting for each element of the product or service’s specification, social values and pricing. You can also indicate any specification elements that are mandatory in order for you to proceed.</p>
            <p>Whether you are looking for specific product specifications, prioritising social responsibility, or ensuring prices are within your budgets, Granular Weightings empower you to efficiently make informed decisions about your procurement.</p>
            <p>Unlock the full potential of the CPRAS Procurement Platform and enjoy a fully customised procurement experience by selecting the button below.</p>
            
            
            <button 
              class="btn bg-gradient-secondary btn-lg w-100 btn-responsive"
              @click="back()"
            >Refine Granular Weightings</button>
          </div>
          <button
            @click="next()"
            :disabled="total != 100"
            type="button"
            class="btn bg-gradient-info btn-lg w-100"
          >
            Submit Overall Weighting
          </button>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        product_match: 45,
        price: 45,
        social_value: 10,
        management_info: 0,
        environment_impact: 0
      }
    }
  },
  props: ['params'],
  computed: {
    total: function() {
      return (
        parseInt(this.form.product_match) +
        parseInt(this.form.price) +
        parseInt(this.form.social_value) +
        parseInt(this.form.management_info)
      )
    }
  },
  created: function () {
    this.form = Object.assign({}, this.params)
  },
  watch: {
    params: {
      handler(oldVal, newVal) {
        this.form = Object.assign({}, this.params)
      },
      deep: true
    },
  },
  methods: {
    next() {
      this.form.submit = true
      this.$emit('search', this.form)
    },
    back() {
      this.form.back = 1
      this.form.step = 1
      this.form.page = 1
      this.$emit('back', this.form)

    },
  }
}
</script>

<style scoped>
.custom-tag {
  font-size: 12px;
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  padding: 5px;
  border-radius: 8px;
  margin: 3px;
}

.total-cost {
  padding: 10px;
  font-size: 20px;
  font-weight: 700;
  color: #f44336;
}

.list-padding-5 > .list-group-item {
  padding: 5px;
}
</style>
