<template>
  <div>
    <hr />
    <div style="text-align:left;margin-bottom: 10px">
      <h5>Social Values</h5>
    </div>
    <table class="table  align-items-center" v-if="form">
      <thead>
        <tr>
          <th></th>
          <!-- <th style="width: 200px"
          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Value</th> -->
          <th style="width: 100px"
          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
            Importance
            <div>(Out of 10)</div>
          </th>
          <th style="    width: 50px;"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="social_value in form.social_values"
          :key="social_value.description"
        >
          <td class="text-sm text-wrap">
            <text-with-show-more
              :string="
                findOneGlobalSocialValueOriginalDescription(
                  social_value.description
                )
              "
              :length="200"
            ></text-with-show-more>
          </td>
          <!-- <td>
            <div class="form-group">
              <div class="form-check">
                <input
                v-if="social_value.type == 2"
                  type="text"
                  class="form-check-input-sm form-control"
                  v-model="social_value.response_value"
                />
                <el-checkbox
                v-if="social_value.type == 1"
                label="Yes"
                            :checked="social_value.response_choice"
                  
                >

                </el-checkbox>
              </div>
            </div>
          </td> -->
          <td>
            <div class="form-group">
              <select
                class="form-select form-select-sm"
                v-model="social_value.level_of_importance"
                ><option v-for="imp in importance" :key="imp">{{
                  imp
                }}</option></select
              >
            </div>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { Category } from '@/api_services/category'

export default {
  props: ['selected'],
  data() {
    return {
      form: null,
      importance: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      global_social_values: []
    }
  },
  watch: {
    selected: function() {
      this.form = Object.assign({}, this.selected)
    }
  },
  created: function() {
    if (this.selected) {
      this.form = Object.assign({}, this.selected)
    }
    this.getGlobalSocialValuesOriginalDescriptions()
  },
  methods: {
    findOneGlobalSocialValueOriginalDescription(description) {
      let text = null
      this.global_social_values.forEach(function(sv) {
        if (sv.description == description) text = sv.original_description
      })

      return text
    },
    getGlobalSocialValuesOriginalDescriptions() {
      let ids = []

      this.form.social_values.forEach(function(spec) {
        ids.push(spec.global_social_value_id)
      })

      this.global_specifications = []
      Category.socialValues({
        id: this.form.category_id,
        per_page: 999,
        spec_ids: ids
      })
        .then(result => {
          let self = this
          result.data.social_values.forEach(function(spec) {
            self.global_social_values.push(spec)
          })
        })
        .catch(error => {})
    }
  }
}
</script>

<style></style>
