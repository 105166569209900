<template>
<div>
  <div>
    <a v-if="is_admin" href="#" @click.prevent="selectCompany()" :class="className">
      {{company ? company.name : 'No Company'}}
      <span class="small ms-1">
        <i class="fa-solid fa-arrow-right-arrow-left"></i>
      </span>
    </a>
  </div>

  <el-drawer id="modal-remove" size="90%" v-model="showCompanyModal" title="Select Company" :append-to-body="true" :close-on-press-escape="false">
    <select-company-component @update="closeSelectCompanyModal"></select-company-component>
  </el-drawer>

  <div v-if="no_company">
    <p class="my-2">
      You are not assigned to any company. Please contact the administrator. Redirecting to the dashboard ...
    </p>
  </div>

</div>
</template>

<script>

import SelectCompanyComponent from './select_company_component.vue'
import Swal from 'sweetalert2'

export default {
  components: {
    SelectCompanyComponent,
  },
  data() {
    return {
      company: null,
      is_admin: true,
      no_company: false,
      showCompanyModal: false,
      companies: []
    }
  },
  props: {
    className: {
      type: String,
      required: false
    }
  },
  created: function () {
    let user = this.$store.state.savedUser
    this.company = user.company

    if (!this.company) {
      if (!user.is_admin || (user.is_admin && this.$router.currentRoute.name !== 'companies')) {
        if (this.$router.currentRoute.name != 'Home') {
          this.no_company = true
          let self = this
          setTimeout(function () {
            self.no_company = true;
            self.redirectToDashboard();
          }, 3000);
        }

      }

    }
  },
  computed: {
    shouldShowDrawer() {
      // Check if the user is an admin or has more than one assigned company
      return this.isAdminOrHasMultipleCompanies;
    },
    isAdminOrHasMultipleCompanies() {
      let user = this.$store.state.savedUser;
      const isAdmin = user.is_admin;
      const userCompaniesCount = this.userCompanies.length;

      // Admin without a specific company can access all companies
      return isAdmin || userCompaniesCount > 1;
    },
    userCompanies() {
      let user = this.$store.state.savedUser;
      return user.companies || [];
    },
  },
  methods: {
    redirectToDashboard() {
      Swal.fire({
        icon: 'warning',
        title: 'Attention',
        text: 'You are not assigned to any company. Please contact the administrator.',
        timer: 3000,
        timerProgressBar: true,
        onClose: () => {
          this.no_company = false
          this.$router.push('/')
        },
      });
    },
    selectCompany() {
      this.showCompanyModal = true
    },
    closeSelectCompanyModal() {
      this.showCompanyModal = false
    }
  }
}
</script>

<style scoped>

</style>
