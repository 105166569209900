<template>
  <div>
    <not-allowed v-if="!$store.state.isViewable('roles')"></not-allowed>

    <div v-else> 
      <h2 class="text-white">Roles</h2>
      <div style="text-align: left;" class="row">
        <div class="col-md-6">
          <div class="card my-3">
            <div class="card-header">
              <nav class="navbar shadow-none my-3 navbar-transparent float-end">
                <div class="bg-white border-radius-lg d-flex me-2">
                  <input
                    type="text"
                    name=""
                    id=""
                    class="form-control form-control-sm border-1 ps-3"
                    v-model="term"
                    placeholder="Search"
                  />
                  <button
                    class="btn bg-gradient-secondary my-1 me-1 btn-sm"
                    @click="search()"
                  >
                    Search
                  </button>
                  <button
                    class="btn bg-gradient-secondary my-1 me-1 btn-sm"
                    @click="create()"
                  >
                    Add
                  </button>
                </div>
              </nav>
            </div>
            <div class="card-body px-0 pb-2">
              <div class="table-responsive">
                <table class="table table-fluid align-items-center mb-0">
                  <thead>
                    <tr>
                      <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        Name
                      </th>
                      <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        Code
                      </th>
                      <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody class="text-sm">
                    <tr v-if="!roles.length">
                      <td v-if="isLoading" colspan="100%" class="text-sm">
                        <i class="fas fa-cog fa-spin me-2"></i>Loading...
                      </td>
                      <td v-else colspan="100%">No data</td>
                    </tr>
                    <tr v-else v-for="role in roles" :key="role.id">
                      <td>{{ role.name }}</td>
                      <td>
                        <span class="badge badge-success">{{ role.code }}</span>
                      </td>
                      <td style="width: 1%">
                        <a
                          class="link me-3"
                          @click="showEdit(role)"
                          v-tooltip="'Edit'"
                        >
                          <i class="fas fa-user-edit text-secondary"></i>
                        </a>
                        <a
                          class="link me-3"
                          @click="showPermissions(role)"
                          v-tooltip="'Permissions'"
                        >
                          <i
                            class="fa-solid fa-unlock-keyhole text-secondary"
                          ></i>
                        </a>
                        <a
                          class="link"
                          @click="showRemove(role)"
                          v-tooltip="'Delete'"
                        >
                          <i class="fas fa-trash text-secondary"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6" v-if="original && edit">
          <RolesFormComponent
            v-on:close_form="closeForm"
            :key="selected.id"
            :roles="selected"
          ></RolesFormComponent>
        </div>

        <div class="col-md-6" v-if="original && show_permissions">
            <RolePermissionComponent
              :key="'role_permission_' + original.name"
              :permissions="permissions"
              :original="original"
              :role_permissions="role_permissions"
              :selected="selected"
              @showpermission="showPermissions"
            ></RolePermissionComponent>
        </div>
      </div>

      <el-dialog
        id="modal-remove"
        v-model="dialogVisible"
        title="Confirm Delete?"
        width="500"
      >
        <div v-if="to_remove">
          You are about to remove {{ to_remove.name }}, Proceed?
        </div>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="dialogVisible = false">Cancel</el-button>
            <el-button type="danger" @click="remove">
              Confirm
            </el-button>
          </div>
        </template>
      </el-dialog>

    </div>
  </div>
</template>

<script>
import { Role } from '@/api_services/role_service'
import $ from 'jquery'
import RolesFormComponent from './RolesFormComponent.vue'
import RolePermissionComponent from './RolePermissionComponent.vue'

export default {
  components: {
    RolesFormComponent, RolePermissionComponent
  },
  data() {
    return {
      isLoading: true,
      roles: [],
      fields: ['id', 'name', 'code', 'action'],
      edit: false,
      selected: null,
      original: null,
      validate: ['name', 'code'],
      to_remove: null,
      term: null,
      permissions: [],
      role_permissions: [],
      show_permissions: false,
      dialogVisible: false
    }
  },
  created: function() {
    this.getPermissions()
    this.getRoles()
  },
  methods: {
    closeForm(value){
      if(value == "save") this.getRoles()
      this.edit = false
      this.original = null
    },
    showPermissions(role) {
      this.getRolePermissions(role)
    },
    getRolePermissions(role) {
      Role.getRolePermissions(role.id, {})
        .then(result => {
          if (result.data.permissions)
            this.role_permissions = result.data.permissions
          this.selected = Object.assign({}, role)
          this.original = Object.assign({}, role)
          this.show_permissions = true
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching role permissions')
          console.error('error:', error.response.data)
        })
    },
    search() {
      this.getRoles()
    },
    remove(bvModalEvt) {
      bvModalEvt.preventDefault()
      Role.delete(this.to_remove)
        .then(result => {
          this.$toast.success('Removed successfully')
          this.$nextTick(() => {
            this.dialogVisible = false
          })
          this.getRoles()
        })
        .catch(error => {
          this.$toast.error('An error occured while removing a role')
          console.error('error:', error.response.data)
        })
    },
    showRemove(type) {
      this.to_remove = Object.assign({}, type)
      this.dialogVisible = true
    },
    showEdit(type) {
      this.edit = true
      this.selected = Object.assign({}, type)
      this.original = Object.assign({}, type)
    },
    getPermissions() {
      this.permissions = []
      Role.getPermissions({})
        .then(result => {
          if (result.data.permissions)
            this.permissions = result.data.permissions
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching permissions')
          console.error('error:', error.response.data)
        })
    },
    getRoles() {
      this.reset()
      this.isLoading = true
      Role.get({ params: { term: this.term } })
        .then(result => {
          if (result.data.roles) this.roles = result.data.roles
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching roles')
          console.error('error:', error.response.data)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    onSubmit(evt) {
      evt.preventDefault()
      var action = this.selected.id
        ? Role.update(this.selected)
        : Role.create(this.selected)
      action
        .then(result => {
          this.$toast.success('Saved successfully')
          this.getRoles()
        })
        .catch(error => {
          this.$toast.error('An error occured while saving a role')
          console.error('error:', error.response.data)
        })
    },
    onCancel(evt) {
      evt.preventDefault()
      this.reset()
    },
    reset() {
      this.original = null
      this.selected = null
      this.edit = false
      this.show_permissions = false
    },
    create() {
      this.original = { name: null, code: null }
      this.selected = Object.assign({}, this.original)
      this.edit = true
    },
  },
  computed: {

  }
}
</script>
