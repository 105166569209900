<template>
  <div class="card mb-2">
    <a 
      @click="showDetails"
    >
    <div style="height: 200px;overflow: hidden; display: flex; align-items: center; margin: auto" >
       <img
          class="img-fluid"
          :src="getProductPrimaryImage(product)
              ? getProductPrimaryImage(product)
              : require('@/assets/no_image.png')
            "
            style="height: auto;width:100%;display: block;
            margin-left: auto;
            margin-right: auto;"
          alt="Image"
        />
    </div>
     
    </a>
    <div class="card-body px-2 py-2"  :class="step > 1 ? 'height300': 'height150'">
      <a
        href="#"
        v-tooltip="'click to view details'"
        @click="showDetails"
        class="no-color-change"
      >
        <span style="font-size: 18px; font-weight: 700;" class="card-title">{{
          product.name
        }}</span>
      </a>

      <div v-if="step == 2">
        <span class="text-bold text-sm">Top Level Score</span><br />
        <span class="text-xs"
          >Feature/Requirements:
          <span style="font-weight: 600;"
            >{{
              product.final_points_list.specs
                ? product.final_points_list.specs.toFixed(2)
                : 0
            }}
            points</span
          > </span
        ><br />
        <span class="text-xs"
          >Pricing:
          <span style="font-weight: 600;"
            >{{
              product.final_points_list.price
                ? product.final_points_list.price.toFixed(2)
                : 0
            }}
            points</span
          ></span
        ><br />
        <span class="text-xs"
          >Social Value:
          <span style="font-weight: 600;"
            >{{
              product.final_points_list.social_value
                ? product.final_points_list.social_value.toFixed(2)
                : 0
            }}
            points</span
          ></span
        ><br />
        <span class="text-xs"
          >Management Info:
          <span style="font-weight: 600;"
            >{{
              product.final_points_list.management_info
                ? product.final_points_list.management_info.toFixed(2)
                : 0
            }}
            points</span
          > </span
        ><br />
        <span class="text-xs"
          >Contract Length:
          <span style="font-weight: 600;">{{
            $store.state.contract_lengths[
              product.product_price_ranges[0].contract_length.option
            ]
          }}</span> </span
        ><br />
        <span class="text-sm" style="font-weight: 700;"
          >Total Score:
          <span style="color: #2b9348; font-weight: 900;"
            >{{ product.over_all_points }} points</span
          > </span
        ><br />
        <span class="text-sm" style="font-weight: 700;"
          >Price/Cost:
          <span style="color:#B12704; font-weight: 900;">{{
            showPrice() 
          }}</span></span
        ><a class="link" v-tooltip="'This is the framework price'"
          ><i class="fa-regular fa-circle-question"></i
        ></a>
      </div>

      <div v-if="step == 1">
        <!-- <div v-if="params.first_search"> -->
        <div>
          <p v-html="showDescription(product)"></p>
          <div v-if="product.prices">
            <span class="text-xs"
              >Price/Cost:
              <span style="color:#B12704; font-weight: 900;">{{
                showPrice()
              }}</span></span
            ><a class="link" v-tooltip="'This is the framework price'"
              ><i class="fa-regular fa-circle-question"></i
            ></a>
          </div>
        </div>
        <!-- <div v-else>
          <span class="text-bold text-sm">Initial Score</span><br />
          <span class="text-xs"
            >Feature/Requirements:
            <span style="color: #2b9348; font-weight: 900;"
              >{{ product.points }} points</span
            > </span
          ><br />
          <div v-if="product.prices">
            <span class="text-xs"
              >Price/Cost:
              <span style="color:#B12704; font-weight: 900;">{{
                showPrice()
              }}</span></span
            ><a class="link" v-tooltip="'This is the framework price'"
              ><i class="fa-regular fa-circle-question"></i
            ></a>
          </div>
        </div> -->
      </div>
      <br />
    </div>
  </div>
</template>

<script>

export default {
  props: ['product', 'preview', 'category_id', 'step', 'volume', 'params'],
  data() {
    return {}
  },
  methods: {
    showDetails(){
      if(this.preview) this.$emit("show_details", true)
      else this.$router.push({ path: '/search-solution/' + this.product.id, query: { category_id: this.category_id, volume: this.volume } })
    },
    viewQA() {
      this.$bvModal.show('modal-question-answer-' + this.product.id)
    },
    closeQA() {
      this.$bvModal.hide('modal-question-answer-' + this.product.id)
    },
    getPrimaryImage(product) {
      var primaryImage = 'null'
      product.product_images.filter(function(image) {
        if (image.is_primary) primaryImage = image.absolute_path
      })
      return primaryImage
    },

    getProductPrimaryImage(product) {
      if (!product) return null
      var image_path = null
      if (!product.product_images) product.product_images = []
      product.product_images.forEach(function(image) {
        if (image.is_primary) image_path = image.absolute_path
      })

      if(this.preview) image_path = product.absolute_path

      return image_path
    },
    getProductImages(product) {
      var images = []
      product.product_images.forEach(function(image) {
        images.push(image.absolute_path)
      })

      return images
    },
    showDescription(product) {
      if (!product.short_description) return ''
      if (!product.short_description.length) return ''
      if (product.short_description.length > 100)
        return product.short_description.substring(0, 100) + '...'
      if (product.short_description == 'undefined')
        product.short_description = ''
      return product.short_description
    },
    showPrice() {
      var roundedNumber = Math.round(this.product.prices)
      // return roundedNumber.toLocaleString()
      // var for_comparison = false

      // this.product.categories.forEach(function(category){
      //   if (category.for_comparison) for_comparison = true
      // })

      // return for_comparison ? "N/A": "£"+this.product.prices
      return '£' + roundedNumber.toLocaleString()
    },
    
  }
}
</script>

<style scoped>
.no-color-change:hover {
  text-decoration: none;
  color: inherit;
}
.custom-tag {
  font-size: 12px;
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  padding: 5px;
  border-radius: 8px;
  margin: 3px;
}

.total-cost {
  padding: 10px;
  font-size: 20px;
  font-weight: 700;
  color: #f44336;
}

.search-product-card:hover {
  cursor: pointer;
  background: #f4fdff;
}

.height150{
  height: 150px;
}
.height200{
  height: 200px;
}
.height300{
  height: 300px;
}
</style>
