<template>
  <div class="container-fluid">
    <div style="text-align:left">
      <button
        type="button"
        class="btn transparent text-white shadow-none"
        @click="$router.back()"
      >
        &#8592; Back
      </button>
      <hr /> 
    </div>
    <div class="card p-4 my-4 text-lg">
      <p>Welcome to your Further Competition Final Result.</p>
      <p>As the 10-day Further Competition period has now concluded, we are pleased to present the outcome of your search on the CPRAS Procurement Platform below.</p>
      <p>Your updated report includes the final pricing from your qualifying suppliers, in addition to any specification adjustments they may have made during the Further Competition period. Our advanced Platform has scored the suppliers' final offerings across each category according to the weightings applied during your search to help you easily make an informed decision about which product or service best addresses your specific needs.</p>
      
      <p>Once you have reviewed the report, you can initiate the call-off process or reject suppliers’ offers using the buttons below.</p>
      <p>Please note that you must upload a signed Framework Access Agreement before you can proceed to call-off any products or services from the CPRAS Procurement Platform.</p>
      <p>If you have any questions, please do not hesitate to contact our support team via the link on your Dashboard or Sidebar. We are here to assist you throughout the procurement process.</p>
    </div>
    <page-not-found v-if="error"></page-not-found>
    <div v-if="buyer_search_query">
      <div class="row">
        <!-- <div class="col-md-4"> -->
          <div class="col-md-12 col-lg-6 col-xl-8 col-xxl-6">
          <div class="card bg-cover">
            <div class="card-body z-index-2 py-6">
                <h4>Product search criteria</h4>
                <h5>
                  Result Status:
                  <span class="text-success text-lg">{{
                    buyer_search_query.status == 2 ? 'Final' : 'Pending'
                  }}</span>
                </h5>
                <p>Date: {{ buyer_search_query.date_created }}</p>
                <!-- <p>
                  Volume/No. of Transactions:
                  {{ buyer_search_query.params.volume }}
                  {{
                    $store.state.volume_types[
                      buyer_search_query.params.volume + 1
                    ]
                  }}
                </p>
                <p>Price/Cost: {{ buyer_search_query.params.cost_per_unit }}</p> -->
                <p>Preferered contract length: {{ buyer_search_query.params.contract_length }} years</p>
                <label for="" class="text-info text-uppercase text-sm">Overall Weighting Information</label>
                <table class="table">
                  <tbody class="text-sm">
                    <tr>
                      <td>Feature/Requirements/Benefits</td>
                      <td class="text-center text-sm">{{ buyer_search_query.params.product_match }} %</td>
                    </tr>
                    <tr>
                      <td>Price</td>
                      <td class="text-center text-sm">{{ buyer_search_query.params.price }} %</td>
                    </tr>
                    <tr>
                      <td>Social Value</td>
                      <td class="text-center text-sm">{{ buyer_search_query.params.social_value }} %</td>
                    </tr>
                    <tr>
                      <td>Management Information</td>
                      <td class="text-center text-sm">{{ buyer_search_query.params.management_info }} %</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <div>
                  <label for="" class="text-info text-uppercase text-sm">Pricing/Cost Weighting</label>
                  <table class="text-sm table">
                    <tbody>
                      <tr>
                        <td>Ongoing Charges</td>
                        <td class="text-center">{{ buyer_search_query.params.pricing_cost_weighting + ' %' }}</td>
                      </tr>
                      <tr>
                        <td>Other Charges</td>
                        <td class="text-center">{{ buyer_search_query.params.additional_cost_weighting + ' %' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- <div v-if="buyer_search_query.params.charges.length">
                  <label class="badge badge-info text-sm">Annual Cost/Price</label>
                  <table>
                    <thead class="text-sm text-wrap">
                      <th>charge name</th>
                      <th>level of importance</th>
                    </thead>
                    <tbody class="text-sm">
                      <tr
                        v-for="charge in buyer_search_query.params.charges"
                        :key="charge.id"
                      >
                        <td>{{ charge.name }}</td>
                        <td class="text-center text-sm">{{ charge.level_of_importance }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div> -->
                <br>
                <div v-if="buyer_search_query.params.specs.length">
                  <label class="text-info text-uppercase text-sm"
                    >Feature/Requirements/Benefits</label
                  >
                  <table class="table table-xs">
                    <thead class="text-uppercase text-xs text-wrap ">
                      <th>Description</th>
                      <th>Mandatory</th>
                      <th>Level of importance</th>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(spec, i) in buyer_search_query.params.specs"
                        :key="i"
                      >
                        <td class="text-sm text-wrap">
                          <text-with-show-more
                            :string="spec.original_description"
                            :length="50"
                          ></text-with-show-more>
                        </td>
                        <td class="text-center text-sm">{{ spec.required ? 'Yes' : 'No' }}</td>
                        <td class="text-center text-sm">{{ spec.weighing }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br>
                <div v-if="buyer_search_query.params.social_values.length">
                  <label class="text-info text-uppercase text-sm"
                    >Social Values</label
                  >
                  <table class="table table-sm">
                    <thead class="text-xs text-wrap">
                      <th>Description</th>
                      <th>Level of importance</th>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(social_value, i) in buyer_search_query.params.social_values"
                        :key="i"
                      >
                        <td class="text-sm text-wrap">
                          <text-with-show-more
                            :string="social_value.original_description"
                            :length="100"
                          ></text-with-show-more>
                        </td>
                        <td class="text-center text-sm">{{ social_value.level_of_importance }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                
          
            </div>
            <!-- <div class="mask bg-gradient-light border-radius-lg"></div> -->
          </div>
        </div>
        <div class="col-md-12 col-lg-6 col-xl-4 col-xxl-6">
          <div class="sticky-container">
            <div class="row">
              <div
                class="col-xxl-6 col-xl-12"
                v-for="product_search_result in buyer_search_query.product_search_results"
                :key="product_search_result.id"
              >
                <div
                  :class="{
                    'call-off-completed':
                      product_search_result.call_off_status == 4
                  }"
                  class="card mb-2"
                >
                <div style="height: 200px;overflow: hidden; display: flex; align-items: center; margin: auto" >
                  <img
                    class="img-fluid img-adjustment"
                    :src="product_search_result.product.image_path"
                    style="height: auto;width:100%;display: block;
                    margin-left: auto;
                    margin-right: auto;"
                  />
                </div>
                                
                  <div class="card-body card-body-adjustment">
                    <h5 class="mb-0">{{ product_search_result.product.name }}</h5>
                    <table class="text-sm">
                      <thead>
                        <th colspan="2">Top Level Results</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Feature/Requirements</td>
                          <td>
                            {{
                              product_search_result.product.final_points_list
                                .specs.toFixed(2)
                            }}
                            points
                          </td>
                        </tr>
                        <tr>
                          <td>Pricing</td>
                          <td>
                            {{
                              product_search_result.product.final_points_list
                                .price.toFixed(2)
                            }}
                            points
                          </td>
                        </tr>
                        <tr>
                          <td>Social Value</td>
                          <td>
                            {{
                              product_search_result.product.final_points_list
                                .social_value.toFixed(2)
                            }}
                            points
                          </td>
                        </tr>
                        <tr>
                          <td>Management Information</td>
                          <td>
                            {{
                              product_search_result.product.final_points_list
                                .management_info.toFixed(2)
                            }}
                            points
                          </td>
                        </tr>
                        <tr>
                          <td><strong>Total Score</strong></td>
                          <td>
                            <strong style="color: #e85d04;">{{ product_search_result.product.total_points.toFixed(2) }}
                            points</strong>
                            
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <!-- <p>
                      <a href="#" @click="showProduct(product_search_result)">Full Details</a>                         
                    </p> -->
                    <p class="text-sm">
                      Call-Off Status:<span class="text-dark"><strong :style="product_search_result.call_off_status == 4? 'color: #058c42' : 'color: #f77f00'">
                        {{
                          $store.state.call_off_statuses[
                            product_search_result.call_off_status
                          ]
                        }}</strong>
                      </span>
                    </p>
                    <div class="d-grid gap-2">
                      <button
                        class="btn btn-dark btn-sm"
                        @click="showNotifySupplierModal(product_search_result)"
                        v-if="buyer_search_query.status == 2"
                        :disabled="buyer_search_query.status == 2 && product_search_result.call_off_status == 5"
                      >
                      {{ getButtonText(product_search_result) }}
                      </button>
                      <button type="button" class="btn btn-light" @click="showCancelConfirmation(product_search_result)" :disabled="buyer_search_query.status == 2 && (product_search_result.call_off_status == 5 || product_search_result.call_off_status == 4 )">Reject Supplier's Offer</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          
        </div>
      </div>
    </div>

    <el-dialog
      title="Call Off Information"
      v-model="modalCallOff"
      width="50%"
      :before-close="onCloseFaca">
       <div v-if="selected" class="p-4">
        <buyer-call-off-component
          :preferred_contract_length="buyer_search_query.params.contract_length"
          :product_search_result="selected"
          @save="hideNotifySupplierModal()"
        ></buyer-call-off-component>
      </div>
    </el-dialog>

     <el-dialog
      title="FAA"
      v-model="show_faca"
      width="90%"
      :before-close="onCloseFaca">
      <div>
        <faca @uploaded="onCloseFaca(true)"></faca>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { BuyerSearchQuery } from '@/api_services/buyer_search_query'
import { ProductSearchResult } from '@/api_services/product_search_result'
import BuyerCallOffComponent from './BuyerCallOffComponent.vue'
import Modal from '@/components/common/Modal'
import Swal from 'sweetalert2';
import Faca from '../dashboard/Faca.vue'


export default {
  components: { BuyerCallOffComponent, Modal, Faca },
  data() {
    return {
      faca_signed: false,
      show_faca: false,
      buyer_search_query: null,
      error: false,
      selected: null,
      buyer_id: this.$route.params.id,
      modalCallOff: null
    }
  },
  watch: {
    selected: {
      handler: 'updateButtonText',
      immediate: true
    },
    call_off_statuses: {
      handler: 'updateButtonText',
      immediate: true
    }
  },
  created: function() {
    if (this.$route.params.id) {
      this.getItem(this.$route.params.id)
    } else {
       this.enablePageNotFound()
    }
  },
  methods: {
    onShowFaca() {
      this.show_faca = true
    },
    onCloseFaca(signed) {
      this.show_faca = false
      if (signed) {
        this.faca_signed = true
      }
      this.modalCallOff = false
      this.selected = null
      this.getItem(this.$route.params.id)
    },
    showCancelConfirmation(selected) {
      if(selected && selected.id) {
        console.log(selected)
        Swal.fire({
          title: 'Are you sure?',
          text: 'If you continue, we will notify the supplier that you do not wish to proceed with the procurement of their product or service. This action cannot be undone.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Continue',
          cancelButtonText: 'Return to Offers',
        }).then((result) => {
          if (result.isConfirmed) {
            this.notifySupplierNotInterested(selected.id);
          }
        });
      } else {
        console.log(this.selected)
      }
      
    },
    notifySupplierNotInterested(id) {
      ProductSearchResult.notifySupplierNotInterested({id: id})
      .then(response => {
        this.$toast.success('Supplier will be notified shortly')
          this.getItem(this.$route.params.id)
      })
      .catch(error => {
        console.error('error:', error.response.data)
      })
    },
    updateButtonText() {
      const selected = this.selected
      if (selected && selected.call_off_status) {
        const status = this.$store.state.call_off_statuses[selected.call_off_status]
        this.buttonText = status === 'Completed' ? 'View Call-Off Details' : 'Call Off'
      } else {
        this.buttonText = null
      }
    },

    getProductStatus(product) {
      return this.$store.state.call_off_statuses[product.call_off_status]
    },

    getButtonText(product) {
      if (this.buyer_search_query.status === 2) {
        if(this.getProductStatus(product) === 'Completed') {
          return 'View Call-Off Details'
        }else if(this.getProductStatus(product) === 'Rejected') {
          return 'Call-off no longer available'
        }else {
          return 'Proceed to Call-Off'
        }
        // return this.getProductStatus(product) === 'Completed'
        //   ? 'View Call-Off Details'
        //   : 'Proceed to Call-Off'
      }
      return ''
    },
    notifySupplier() {
      ProductSearchResult.notifySupplier({ id: this.selected.id })
        .then(result => {
            this.$toast.success('Supplier will be notified shortly')
        })
        .catch(error => {
          console.error('error:', error.response.data)
        })
    },
    showNotifySupplierModal(object) {
      let user = this.$store.state.savedUser
      if (user && user.company.faca_status != 1) {
        this.faca_signed = false
        this.onShowFaca()
        return false
      }
      this.selected = Object.assign({}, object)
      this.modalCallOff = true
    },
    hideNotifySupplierModal() {
      this.selected = null
      this.modalCallOff = false
      this.getItem(this.buyer_id)
    },
    getItem(id) {
      this.globalState.loading = true

      BuyerSearchQuery.show(id)
        .then(result => {
          if (result.data.buyer_search_query) {
               this.buyer_search_query = result.data.buyer_search_query
          }
        })
        .catch(error => {
          this.enablePageNotFound()
        })
        .finally( () => {
          this.globalState.loading = false
        })
    },
    enablePageNotFound() {
        this.error = true
    }
  }
}
</script>

<style scoped>
  .call-off-completed {
        background: #dbecfd;
  }

  .sticky-container {
  position: sticky;
  top: 0;
  /* height: 100vh;  */
  /* Adjust the height as per your requirement */
  overflow-y: auto;
  padding: 10px;
}
.card-body-adjustment {
min-height: 400px;
}
@media (min-width: 2000px) {
  .img-adjustment {
    width: 80% !important;
  }
}
@media (max-width: 1800px) {
  .card-body-adjustment {
    min-height: 440px;
  }
}
@media (max-width: 1450px) and (min-width: 1400px) {
  .card-body-adjustment {
    min-height: 570px;
  }
}

</style>
