<template>
  <div v-if="company">
    <div v-if="has_completed_supplier_status == 2">
      <h4 class="text-center text-white">Please complete the Company Information sheet</h4>
       
      
      <supplier-all-informations-component :goStep1="goStep1" @update="updateSupplier($event)" :supplier="form"></supplier-all-informations-component>
    </div>
    
    <div v-if="has_completed_supplier_status == 1 && $store.state.isAllowed('company', 'create')">
      <h2 class="text-white">Manage Company</h2>
      <div class="row" v-if="!selected">
        <div class="col-md-3">
          <div class="card">
            <img :src="form.absolute_logo_path || $store.state.no_image" fluid alt="Responsive image" style="height: 300px">
            <div class="card-body text-center">
              <button type="button" class="btn btn-success" @click="edit()">Change Company Photo</button>
            </div>
          </div> 
        </div>
        <div class="col-md-9">
          <div class="card">
            <!-- <div class="card-header text-center"><h4><i class="fa-solid fa-circle-user"></i> Basic Information</h4></div> -->
            <div class="card-header">
              <h4>
                <i class="fa-solid fa-circle-user"></i> Basic Information
                <button type="button" class="btn btn-transparent shadow-none p-0" @click="editInformations()" style="float: right;"
                        v-tooltip="tooltipText">
                  <i style="font-size: x-large;" class="fa-regular fa-edit"></i>
                </button>
              </h4>
            </div>

            <div class="card-body">
              <h2>{{ company.name }}</h2>
              <div><i class="fa-solid fa-map-location"></i> Address: {{ form.complete_address }}</div>
              <div><i class="fa-solid fa-globe"></i> Website: {{ form.website || 'No Website' }}</div>
              <div><i class="fa-solid fa-link"></i> e-catalog: {{ form.e_catalog || 'No Catalog'}}</div>
              <div><i class="fa-solid fa-chart-pie"></i> Postal: {{ form.postal || 'No Postal address'}}</div>
              <div><i class="fa-solid fa-mobile-button"></i> {{ form.phone || 'No Mobile number'}}</div>
              <div><i class="fa-solid fa-envelope" ></i> {{ form.email || 'No Email'}}</div>
              <br>
              <!-- <button type="button" class="btn bg-gradient-success" @click="editInformations()">
                {{ this.$store.state.isUserABuyer() ? "Edit Company Details" : "Edit All Information" }}
              </button> -->
            </div>

          </div>
          <div class="card mt-3">
            <div class="card-header"><h4><i class="fa-solid fa-bag-shopping"></i> Company Details</h4></div>
            <div class="card-body">
              <table class="table table-hover table-borderless">
                <tbody>
                  <!--<tr v-if="$store.state.isViewable('supplier') && form.supplier_detail">
                    <td>Call Off Pre-Sign</td>
                    <td>{{ (form.supplier_detail.call_off_pre_sign) ? 'Yes' : 'No'}}</td>
                  </tr>-->
                  <tr>
                    <td>Office Address</td>
                    <td>{{ (form.supplier_detail.office_address) ? form.supplier_detail.office_address : "N/A" }}</td>
                  </tr>
                  <tr>
                    <td>Trading Status</td>
                    <td v-if="!form.supplier_detail.other_trading_status">{{ (form.supplier_detail.trading_status) ? form.supplier_detail.trading_status : "N/A" }}</td>
                    <td v-else>{{ form.supplier_detail.other_trading_status }}</td>
                  </tr>
                  <tr>
                    <td>Registration Date</td>
                    <td>{{ (form.supplier_detail.registration_date) ? form.supplier_detail.registration_date : "N/A" }}</td>
                  </tr>
                  <tr>
                    <td>Company Registration #</td>
                    <td>{{ (form.supplier_detail.company_registration_number) ? form.supplier_detail.company_registration_number : "N/A" }}</td>
                  </tr>
                  <tr>
                    <td>Charity Registration #</td>
                    <td>{{ (form.supplier_detail.charity_registration_number) ? form.supplier_detail.charity_registration_number : "N/A" }}</td>
                  </tr>
                  <tr>
                    <td>Head Office DUNS #</td>
                    <td>{{ (form.supplier_detail.head_office_duns_number) ? form.supplier_detail.head_office_duns_number : "N/A" }}</td>
                  </tr>
                  <tr>
                    <td>Registered VAT #</td>
                    <td>{{ (form.supplier_detail.registered_vat_number) ? form.supplier_detail.registered_vat_number : "N/A" }}</td>
                  </tr>
                  <tr v-if="form.supplier_detail.is_registered_with_trade_register">
                    <td>Relevant Registration Numbers</td>
                    <td>{{ form.supplier_detail.registered_vat_number_relevant_details }}</td>
                  </tr>
                  <tr v-if="form.supplier_detail.is_it_legal_requirement">
                    <td>Legal Requirement Details</td>
                    <td>{{ form.supplier_detail.is_it_legal_requirement_additional_details }}</td>
                  </tr>
                  <tr>
                    <td>Trading Names</td>
                    <td>{{ (form.supplier_detail.trading_names) ? form.supplier_detail.trading_names : "N/A" }}</td>
                  </tr>
                  <tr>
                    <td>Enterprise Type</td>
                    <td>{{ (form.supplier_detail.enterprise_type) ? form.supplier_detail.enterprise_type : "N/A" }}</td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <div class="container-fluid" v-if="form.supplier_detail.pscs">
                        <table v-for="psc in form.supplier_detail.pscs" :key="psc.id">
                          <tr>
                            <td colspan="2">Details of Persons of Significant Control (PSC) </td>
                          </tr>
                          <tr>
                            <td>Name</td>
                            <td>{{ (psc.name) ? psc.name : "N/A" }}</td>
                          </tr>
                          <tr>
                            <td>Date of Birth</td>
                            <td>{{ (psc.date_of_birth) ? psc.date_of_birth : "N/A" }}</td>
                          </tr>
                          <tr>
                            <td>Nationality</td>
                            <td>{{ (psc.nationality) ? psc.nationality : "N/A" }}</td>
                          </tr>
                          <tr>
                            <td>Country, state</td>
                            <td>{{ (psc.country_state) ? psc.country_state : "N/A" }}</td>
                          </tr>
                          <tr>
                            <td>Service Address</td>
                            <td>{{ (psc.service_address) ? psc.service_address : "N/A" }}</td>
                          </tr>
                          <tr>
                            <td>PSC Join Date</td>
                            <td>{{ (psc.join_date) ? psc.join_date : "N/A" }}</td>
                          </tr>
                          <tr>
                            <td>PSC Condition(s) met</td>
                            <td>{{ (psc.conditions) ? psc.conditions : "N/A" }}</td>
                          </tr>
                        </table>

                      </div>
                    </td>
                  </tr>
                  
                  <tr>
                    <td colspan="2">Details of immediate parent company </td>
                  </tr>
                  <tr>
                    <td>Full name of the immediate parent company</td>
                    <td>{{ (form.supplier_detail.i_parent_company_name) ? form.supplier_detail.i_parent_company_name : "N/A" }}</td>
                  </tr>
                  <tr>
                    <td>Registered Office Address</td>
                    <td>{{ (form.supplier_detail.i_parent_company_office_address) ? form.supplier_detail.i_parent_company_office_address : "N/A" }}</td>
                  </tr>
                  <tr>
                    <td>Registered Office Website Address</td>
                    <td>{{ (form.supplier_detail.i_parent_company_website_address) ? form.supplier_detail.i_parent_company_website_address : "N/A" }}</td>
                  </tr>
                  <tr>
                    <td>Registration #</td>
                    <td>{{ (form.supplier_detail.i_parent_company_registration_number) ? form.supplier_detail.i_parent_company_registration_number : "N/A" }}</td>
                  </tr>
                  <tr>
                    <td>Head Office DUNS #</td>
                    <td>{{ (form.supplier_detail.i_parent_company_duns_number) ? form.supplier_detail.i_parent_company_duns_number : "N/A" }}</td>
                  </tr>
                  <tr>
                    <td>Head Office VAT #</td>
                    <td>{{ (form.supplier_detail.i_parent_company_vat_number) ? form.supplier_detail.i_parent_company_vat_number : "N/A" }}</td>
                  </tr>
                  <tr>
                    <td colspan="2">Details of ultimate parent company </td>
                  </tr>
                  <tr>
                    <td>Full name of the ultimate parent company</td>
                    <td>{{ (form.supplier_detail.u_parent_company_name) ? form.supplier_detail.u_parent_company_name : "N/A" }}</td>
                  </tr>
                  <tr>
                    <td>Registered Office Address</td>
                    <td>{{ (form.supplier_detail.u_parent_company_office_address) ? form.supplier_detail.u_parent_company_office_address : "N/A" }}</td>
                  </tr>
                  <tr>
                    <td>Registered Office Website Address</td>
                    <td>{{ (form.supplier_detail.u_parent_company_website_address) ? form.supplier_detail.u_parent_company_website_address : "N/A" }}</td>
                  </tr>
                  <tr>
                    <td>Registration #</td>
                    <td>{{ (form.supplier_detail.u_parent_company_registration_number) ? form.supplier_detail.u_parent_company_registration_number : "N/A" }}</td>
                  </tr>
                  <tr>
                    <td>Head Office DUNS #</td>
                    <td>{{ (form.supplier_detail.u_parent_company_duns_number) ? form.supplier_detail.u_parent_company_duns_number : "N/A" }}</td>
                  </tr>
                  <tr>
                    <td>Head Office VAT #</td>
                    <td>{{ (form.supplier_detail.u_parent_company_vat_number) ? form.supplier_detail.u_parent_company_vat_number : "N/A" }}</td>
                  </tr>
                  </tbody>
                </table>
            </div>

          </div>
          <div class="card mt-3" v-if="!$store.state.isUserABuyer() && form.supplier_bidding_model">
            <div class="card-header"><h4><i class="fa-solid fa-stamp"></i> Bidding Model</h4></div>
            <div class="card-body">
              <table class="table table-hover table-borderless">
                <tbody >
                <tr>
                  <td class="text-wrap">Are you bidding as the lead contact for a group of economic operators?</td>
                  <td>{{ (form.supplier_bidding_model.are_you_lead_contact) ? "yes" : "no" }}</td>
                </tr>
                <tr>
                  <td class="text-wrap">Name of group of economic operators</td>
                  <td>{{ (form.supplier_bidding_model.name_of_group_economic_operators) ? form.supplier_bidding_model.name_of_group_economic_operators : "N/A" }}</td>
                </tr>
                <tr>
                  <td class="text-wrap">Proposed legal structure if the group of economic operators intends to form a named single legal entity prior to signing a contract, if awarded. If you do not propose to form a single legal entity, please explain the legal structure.</td>
                  <td>{{ (form.supplier_bidding_model.proposed_legal_structure) ? form.supplier_bidding_model.proposed_legal_structure : "N/A" }}</td>
                </tr>
                <tr>
                  <td colspan="2">
                    <div class="container-fluid" v-if="form.supplier_bidding_model.are_you_using_subcontractor">
                      <div v-if="form.supplier_bidding_model.subcontractors">
                        <table v-for="subcontractor in form.supplier_bidding_model.subcontractors" :key="subcontractor.id">
                          <tr>
                            <td colspan="2">Subcontractor Detail </td>
                          </tr>
                          <tr>
                            <td>Name</td>
                            <td>{{ (subcontractor.name) ? subcontractor.name : "N/A" }}</td>
                          </tr>
                          <tr>
                            <td>Registered Address</td>
                            <td>{{ (subcontractor.registered_address) ? subcontractor.registered_address : "N/A" }}</td>
                          </tr>
                          <tr>
                            <td>Trading Status</td>
                            <td>{{ (subcontractor.trading_status) ? subcontractor.trading_status : "N/A" }}</td>
                          </tr>
                          <tr>
                            <td>Company Registration #</td>
                            <td>{{ (subcontractor.registration_number) ? subcontractor.registration_number : "N/A" }}</td>
                          </tr>
                          <tr>
                            <td>Head Office DUNS #</td>
                            <td>{{ (subcontractor.duns_number) ? subcontractor.duns_number : "N/A" }}</td>
                          </tr>
                          <tr>
                            <td>Registered VAT #</td>
                            <td>{{ (subcontractor.vat_number) ? subcontractor.vat_number : "N/A" }}</td>
                          </tr>
                          <tr>
                            <td>Type of Organisation</td>
                            <td>{{ (subcontractor.organisation_type) ? subcontractor.organisation_type : "N/A" }}</td>
                          </tr>
                          <tr>
                            <td>SME</td>
                            <td>{{ (subcontractor.sme) ? "yes" : "no" }}</td>
                          </tr>
                          <tr>
                            <td>The role each sub-contractor will take in providing the works and /or supplies e.g. key deliverables</td>
                            <td>{{ (subcontractor.role) ? subcontractor.role : "N/A" }}</td>
                          </tr>
                          <tr>
                            <td>The approximate % of contractual obligations assigned to each sub-contractor</td>
                            <td>{{ (subcontractor.contractual_percentage) ? subcontractor.contractual_percentage : "N/A" }}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="card mt-3" v-if="!$store.state.isUserABuyer() && form.supplier_contact_detail">
            <div class="card-header"><h4><i class="fa-solid fa-address-book"></i> Contact Details</h4></div>
            <div class="card-body">
              <table class="table table-hover table-borderless">
                <tbody>
                <tr>
                  <td>Contact Name</td>
                  <td>{{ (form.supplier_contact_detail.contact_name) ? form.supplier_contact_detail.contact_name : "N/A" }}</td>
                </tr>
                <tr>
                  <td>Organisation Name</td>
                  <td>{{ (form.supplier_contact_detail.organisation_name) ? form.supplier_contact_detail.organisation_name : "N/A" }}</td>
                </tr>
                <tr>
                  <td>Role</td>
                  <td>{{ (form.supplier_contact_detail.role) ? form.supplier_contact_detail.role : "N/A" }}</td>
                </tr>
                <tr>
                  <td>Phone Number</td>
                  <td>{{ (form.supplier_contact_detail.phone_number) ? form.supplier_contact_detail.phone_number : "N/A" }}</td>
                </tr>
                <tr>
                  <td>Email Address</td>
                  <td>{{ (form.supplier_contact_detail.email_address) ? form.supplier_contact_detail.email_address : "N/A" }}</td>
                </tr>
                <tr>
                  <td>Postal Address</td>
                  <td>{{ (form.supplier_contact_detail.postal_address) ? form.supplier_contact_detail.postal_address : "N/A" }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    
    <div class="row" v-if="selected">
      <div class="col-md-4"></div>
      <div class="col-md 4">
        <supplier-logo-form-component @update="updateFromLogo($event)" v-if="edit_logo"></supplier-logo-form-component>
      </div>
      <div class="col-md-4"></div>
    </div>
    
    <supplier-all-informations-component :goStep1="goStep1" @update="updateSupplier($event)" :supplier="selected" v-if="!edit_logo && selected"></supplier-all-informations-component>
    </div>
  </div>
</template>

<script>
import SupplierAllInformationsComponent from './SupplierAllInformationsComponent.vue'

import SupplierLogoFormComponent from './SupplierLogoFormComponent.vue'

export default {
  components: { SupplierAllInformationsComponent, SupplierLogoFormComponent },
  data() {
    return {
      goStep1: false,
      counter: 0,
      has_completed_supplier_status: 0,
      edit_logo: false,
      company: null,
      selected: null,
      form: {
        name: null,
        complete_address: null,
        website: null,
        e_catalog: null,
        postal: null,
        supplier_detail: {
        'call_off_pre_sign': 0, 'pscs': null, 'name': null,'office_address': null, 'email': null, 'phone': null, 'e_catalog': null , 'website_address': null, 'trading_status': null,
        'other_trading_status' :null,'registration_date': null,'company_registration_number' :null,'charity_registration_number': null,
        'head_office_duns_number': null,'registered_vat_number': null,'is_registered_with_trade_register': false,'registered_vat_number_relevant_details': null,
        'is_it_legal_requirement': false,'is_it_legal_requirement_additional_details': null, 'trading_names': null,'enterprise_type': null,
        'psc_name': null,'psc_date_of_birth': null,'psc_nationality': null,'psc_country_state': null,'psc_service_address':null,'psc_join_date':null,'psc_conditions':null,
        'i_parent_company_name':null,'i_parent_company_office_address':null,'i_parent_company_website_address':null,'i_parent_company_registration_number':null,
        'i_parent_company_duns_number':null,'i_parent_company_vat_number':null,
        'u_parent_company_name':null,'u_parent_company_office_address':null,'u_parent_company_website_address':null,'u_parent_company_registration_number':null,
        'u_parent_company_duns_number':null,'u_parent_company_vat_number':null
        },
        supplier_bidding_model:{
          'subcontractors':null, 'are_you_lead_contact':false,'name_of_group_economic_operators':null,'proposed_legal_structure':null, 'are_you_using_subcontractor':false
        },
        supplier_contact_detail:{
          'supplier_id': null, 'contact_name': null, 'organisation_name': null, 'role': null,  'phone_number': null, 'email_address': null,
          'postal_address': null, 'signature': null, 'date': null
        }
      }
    }
  },
  created: function() {

    let self = this
    this.$store.state.waitForUser(function(res){
      if (res) {
        self.init()
      }
    })
  },
  computed: {
    tooltipText() {
      return this.$store.state.isUserABuyer() ? "Edit Company Details" : "Edit All Information";
    }
  },
  methods: {
    init() {
      this.company = this.$store.state.savedUser.company
      if (this.company && !this.company.supplier) {
        this.has_completed_supplier_status = 2
        
        if(!this.$store.state.isUserABuyer()){
          this.form = { name: null }
        }
      }

      if (this.company && this.company.supplier) {
        this.form = Object.assign({}, this.company.supplier);
        this.has_completed_supplier_status = this.company.supplier.has_completed_information ? 1 : 2

        if (!this.form.supplier_contact_detail) this.form.supplier_contact_detail = {}
        if (!this.form.supplier_detail) this.form.supplier_detail = {}
        if (!this.form.supplier_bidding_model) this.form.supplier_bidding_model = {}

        if ((this.form.supplier_detail && Object.keys(this.form.supplier_detail).length > 0) && this.$store.state.isUserABuyer()) {
          this.has_completed_supplier_status = 1
        }
      }

      this.selected = null
    },
    updateSupplier(object) {
      if (object.supplier) this.form = Object.assign({}, object.supplier);
      this.$store.commit('saveSupplier',object.supplier);
      if (object.all_info) this.selected = Object.assign({}, object.supplier);
      else this.selected = null

      this.init()
    },
    updateFromLogo(object) {
      this.has_completed_supplier_status = 1
      this.selected = null
      this.edit_logo = false
      this.init()
    },
    editInformations() {
      this.selected = Object.assign({}, this.form);
      this.edit_logo = false

      if(this.$store.state.isUserABuyer()) this.goStep1 = true
    },
    edit() {
      this.selected = Object.assign({}, this.form);
      this.edit_logo = true
    }
  }
}
</script>
