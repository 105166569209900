import api from './api'

const API = '/api/buyer_search_queries'


export const BuyerSearchQuery = {
  getBankingTemplate: function(params) {
    return api.get(API+"/banking-template", params)
  },
  get: function(params) {
    return api.get(API, params)
  },
  submitCallOffFromBuyer: function(params) {
    return api.create(API + '/submit-call-off-from-buyer', params)
  },
  submitCallOffFromSupplier: function(params) {
    return api.create(API + '/submit-call-off-from-supplier', params)
  },
  supplierPendingCallOffs: function(params) {
    return api.get(API + '/supplier-pending-call-off', params)
  },  
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  create: function(params) {
    return api.createFormData(API, params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id)
  },
  downloadCallOffPreviewLink: function() {
    return process.env.VUE_APP_API_ROOT+'/download-call-off-preview'
  },
  getCallOffDocuments: function(params) {
    return api.get(API + '/call-off-documents', params)
  },
  downloadFinalReport: function(id) {
    return api.get(API + '/' + id + '/download-final-report')
  }
}
