import api from './api'
import { LocalStorageService } from '@/services/LocalStorageService'
import { User } from './user'
import store from '../store/index'
const API = '/api/login'
const API_REGISTER = '/api/register'
import helper from '../mixins/helper'

export const Authenticate = {
  register: function(params) {
    return api.create(API_REGISTER, params)
  },
  verifyResetToken: function(params) {
    return api.create('/api/verify_reset_token', params)
  },
  resetPassword: function(params) {
    return api.create('/api/reset_password', params)
  },
  forgotPassword: function(params) {
    return api.create('/api/forgot-password', params)
  },
  login: function(params, cb) {
    api
      .create(API, params)
      .then(result => {
        var token = result.data.access_token
        store.commit('saveToken', token)

        this.getUser(function(response) {
          cb(response.user, '')
        })
      })
      .catch(error => {
        var message = 'An error occured while authenticating'
        if (error.response.data) {
          if (error.response.data.error) message = error.response.data.error
        }
        cb(false, message)
      })
  },
  getUser: function (cb) {
    let params = {
      mac_address: store.state.mac_address,
      device_type: helper.getDeviceType()
    }
    
    User.currentUser(params)
      .then(result => {
        if (result.data.user) {
          store.commit('saveUser', result.data.user)
        }
        cb(result.data)
      })
      .catch(error => {
        console.log(error)
        cb(error)
      })
  }
}
