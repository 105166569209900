<template>
    <div>
        <div class="row">
            <div class="col-md-6">
                <h2>Logging out...</h2>
            </div>
        </div>
  </div>
</template>

<script>
import { LocalStorageService } from '@/services/LocalStorageService'
import { Authenticate } from '@/api_services/authenticate'

export default {
    name: 'LogoutComponent',
    created: function() {
        var self = this
        setTimeout(function(){ self.logout() }, 1000);
    },
    methods: {
      logout() {
            this.$store.commit('saveUser',null);
            LocalStorageService.setToken(null)
            LocalStorageService.setUser(null)
            this.$router.push({name: 'Home'})
      }
    }
}
</script>

<style lang="scss" scoped></style>
