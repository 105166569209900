<template>
  <div class="container">
    <loading :loading="loading"></loading>
    <div class="card mx-auto my-3" v-if="submitted">
      <h4>Thank you for submitting your statement</h4>
      <div v-if="!product">
        <button
          class="btn bg-gradient-info btn-sm"
          type="button"
          @click="clear"
        >
          Submit a new statement
        </button>
      </div>
    </div>
    <div class="mx-auto my-3" v-if="!submitted">
      <div>
        <div class="text-lg text-bold" v-if="buyer_query_id">
          <span class="text-sm text-wrap">You can upload multiple files by either selecting them altogether or doing it one by one by clicking Drop or select file button multiple times before selecting Submit</span>
        </div>

        <div v-if="product">
          <h2>Product {{ product.name }}</h2>
          <h4>Comparison Inquiry</h4>
          <hr />
        </div>
        <div v-else>
          <h2 v-if="!buyer_query_id">
            Need help in generating a comparison report?
          </h2>
        </div>
        <div class="alert alert-primary text-white" v-if="!buyer_query_id">
          Upload your statement/invoice and we will do the rest! Just fill out
          the form below
        </div>
      </div>
      <div>
        <div class="container">
          <form @submit="onSubmit">
            <div class="form-grouo">
              <label
                id="input-group-2"
                for="input-2"
                valid-feedback=""
                :invalid-feedback="invalidFeedback('name')"
                :state="validation('name')"
                >Name</label
              >
              <input
                id="input-2"
                v-model="form.name"
                placeholder="Enter name"
                required
                :state="validation('name')"
                :disabled="buyer"
                class="form-control form-control-sm"
              />
            </div>
            <div class="form-grouo">
              <label
                id="input-group-1"
                for="input-1"
                valid-feedback=""
                :invalid-feedback="invalidFeedback('email')"
                :state="validation('email')"
                >Email Address
              </label>
              <input
                id="input-1"
                type="email"
                v-model="form.email"
                placeholder="Enter email"
                required
                :state="validation('email')"
                :disabled="buyer"
                class="form-control form-control-sm"
              />
              <span class="text-xxs text-primary"
                >We'll never share your email with anyone else.</span
              >
            </div>
            <div class="form-grouo">
              <label
                id="input-group-3"
                for="input-3"
                valid-feedback=""
                :invalid-feedback="invalidFeedback('category')"
                :state="validation('category')"
                >Comparison for:
              </label>
              <select
                class="form-select form-select-sm"
                id="input-3"
                v-model="form.category"
                :options="frameworks"
                required
                :state="validation('category')"
                :disabled="!isNaN(category_id)"
              >
                <option
                  v-for="framework in frameworks"
                  :key="framework.id"
                  :value="framework.value"
                  >{{ framework.text }}</option
                >
              </select>
            </div>
            <div class="form-grouo" style="overflow:scroll">
              <label
                valid-feedback=""
                :invalid-feedback="invalidFeedback('files')"
                :state="validation('files')"
              ></label>
              <dropzone
                label="Drop or select file"
                :max-files="5"
                :clean="clearUpload"
                :multiple="true"
                extensions="xlsx, pdf, docx"
                @input="setFile($event)"
              ></dropzone>
              <!-- <b-form-file
                multiple
                v-model="form.files"
                placeholder="Choose files or drop it here..."
                drop-placeholder="Drop files here..."
                :state="validation('files')"
                accept=".xlsx, .pdf, .docx"
              ></b-form-file> -->
              <!-- <small class="form-text text-muted"
                >Accepts excel, word document, and pdf only</small
              > -->
              <!-- <div class="mt-3 text-sm">
                Selected files:
                <div v-for="(file, i) in form.files" v-bind:key="i">
                  {{ file.name }}
                  <b-button style="margin-left: 5px;" @click="removeFile(i)">
                    <b-icon icon="file-earmark-x"></b-icon>
                  </b-button>
                </div>
              </div> -->
            </div>
            <button
              class="btn bg-gradient-success btn-lg w-100 my-3"
              type="submit"
              @click="onSubmit"
              :disabled="!validateAll"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UserHelpService } from '@/api_services/user_help_service'
import { User } from '@/api_services/user'
import { Category } from '@/api_services/category'
import Dropzone from '@/components/common/Dropzone.vue'


export default {
  beforeUnmount() {
    this.loading = false
  },
  props: ['product', 'category_id', 'buyer', 'buyer_query_id'],
  components: {
    Dropzone
  },
  data() {
    return {
      submitted: false,
      loading: false,
      validate: ['name', 'category', 'email', 'files'],
      form: {
        email: '',
        name: '',
        category: null,
        files: [],
        product_id: null,
        buyer_query_id: null
      },
      clearUpload: 0,
      file: null,
      frameworks: [
        { text: '-- Select One --', value: null },
        { text: 'Banking', value: 1 },
        { text: 'Merchant Services', value: 2 },
        { text: 'Direct Debits', value: 3 },
        { text: 'Payment Gateway', value: 4 },
        { text: 'Ivr & Call Masking', value: 5 },
        { text: 'Innovation fs', value: 6 }
      ],
      user: this.$store.state.savedUser
    }
  },
  computed: {
    validateAll() {
      var self = this
      var response = true
      this.validate.forEach(function(field) {
        if (!self.validation(field)) response = false
      })

      return response
    }
  },
  created: function() {
    if (this.product) this.form.product_id = this.product.id
    if (this.category_id) this.form.category = this.category_id
    if (this.buyer_query_id) this.form.buyer_query_id = this.buyer_query_id
    if (this.buyer) {
      this.form.name = this.user.name
      this.form.email = this.user.email
    }

    this.getCategories()
    this.clearUpload++
  },
  methods: {
    setFile(files) {
      let self = this
      this.form.files = []
      files.forEach(file => {
        self.form.files.push(file)
      })
    },
    getCategories() {
      Category.get({ params: {} })
        .then(result => {
          if (result.data.categories) {
            this.frameworks = [{ text: '-- Select One --', value: null }]
            let self = this
            result.data.categories.forEach(function(cat) {
              self.frameworks.push({
                text: cat.name,
                value: cat.id
              })
            })
          }
        })
        .catch(error => {
          this.$toast.error('An error occured while fetching categories')
          console.error('error:', error.response.data)
        })
    },
    removeFile(i) {
      this.form.files.splice(i, 1)
    },
    validation(field) {
      switch (field) {
        case 'name':
          if (!this.form.name) this.form.name = ''
          return this.form.name.length > 0
        case 'category':
          return this.form.category != null
        case 'files':
          return this.form.files.length > 0
        case 'email':
          if (!this.form.email) this.form.email = ''
          return (
            this.form.email.length > 0 && this.isEmailValid(this.form.email)
          )
        default:
          return true
      }
    },
    isEmailValid(string) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(string).toLowerCase())
    },
    invalidFeedback(field) {
      if (!this.validation(field) && field == 'files')
        return 'At least one file is required'
      if (!this.validation(field) && field == 'name') return 'Name is required'
      if (!this.validation(field) && field == 'category')
        return 'Category is required'
      if (!this.validation(field) && field == 'email') {
        if (!this.isEmailValid(this.form.email))
          return 'Email format is invalid'
        return 'Email is required'
      }
      return ''
    },
    getFormData() {
      var formData = new FormData()
      this.form.files.forEach(function(file, index) {
        formData.append('file_' + index, file)
      })
      formData.append('files_count', this.form.files.length)
      formData.append('name', this.form.name)
      formData.append('email', this.form.email)
      formData.append('category', this.form.category)
      if (this.form.product_id)
        formData.append('product_id', this.form.product_id)
      if (this.form.buyer_query_id)
        formData.append('buyer_query_id', this.form.buyer_query_id)
      return formData
    },
    clear() {
      this.submitted = false
      this.form = {
        name: null,
        email: null,
        category: null,
        files: [],
        product_id: null,
        buyer_query_id: null
      }
    },
    onSubmit(evt) {
      this.loading = true
      evt.preventDefault()
      var formData = this.getFormData()
      var action = UserHelpService.create(formData)
      action
        .then(result => {
          this.submitted = true
          // let self = this
          // setTimeout(function () {
           
          // }, 1000);
           this.$emit('submitted', true)
        })
        .catch(error => {
          this.$toast.error('An error occured while submitting request')
          console.error('error:', error.response.data)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>
